import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import SEO from './SEO'
import "./MusicGuesser.css";

const AUTH_SERVER_BASE = "https://music-guesser.com/api";
const SOCKET_SERVER_BASE = "https://music-guesser.com/";

const createSocket = () => io(SOCKET_SERVER_BASE, {
  path: '/socket.io/',
  transports: ['websocket'],
  reconnectionAttempts: 5,
  reconnectionDelay: 1000
});

const MusicGuesser = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [room, setRoom] = useState(null);
  const [players, setPlayers] = useState([]);
  const [scores, setScores] = useState({});
  const [artist, setArtist] = useState(null);
  const [track, setTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [audio] = useState(new Audio());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [guess, setGuess] = useState("");
  const [guessResult, setGuessResult] = useState(null);
  const [isCorrectGuess, setIsCorrectGuess] = useState(false);
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [gameMode, setGameMode] = useState(null);
  const [artistTracks, setArtistTracks] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const [isConnectedToRoom, setIsConnectedToRoom] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [currentPlayerId, setCurrentPlayerId] = useState(null);
  const [currentPlayerTurn, setCurrentPlayerTurn] = useState(null);
  const [songSuggestions, setSongSuggestions] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [copyFeedback, setCopyFeedback] = useState('');
  const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [playerNumber, setPlayerNumber] = useState(null);
  const [socket, setSocket] = useState(null);

  const { roomId } = useParams();
  const navigate = useNavigate();
  const artistInputRef = useRef(null);

  useEffect(() => {
    if (gameMode === 'multi' && !socket) {
      const newSocket = createSocket();
      setSocket(newSocket);
    }
  }, [gameMode, socket]);

  const resetGuessState = useCallback(() => {
    setGuess("");
    setGuessResult(null);
    setShowHint(false);
    setIsCorrectGuess(false);
    setIsAnswerRevealed(false);
    setSongSuggestions([]);
  }, []);

  const joinRoom = useCallback((roomId, rejoinAsPlayer = null) => {
    if (socket && (!hasJoinedRoom || room?.id !== roomId)) {
      console.log('Attempting to join room:', roomId, 'as player:', rejoinAsPlayer);
      socket.emit('joinRoom', { roomId, rejoinAsPlayer });
    }
  }, [socket, room, hasJoinedRoom]);
  

  useEffect(() => {
    if (roomId) {
      setGameMode('multi');
      if (socket) {
        joinRoom(roomId);
      }
    }
  }, [roomId, socket, joinRoom]);

  useEffect(() => {
    if (socket == null) return;
  
    const onConnect = () => {
      console.log('Connected to server with ID:', socket.id);
      setConnectionStatus('connected');
      setCurrentPlayerId(socket.id);
      setError(null);
      setReconnectAttempts(0);
      
      // Attempt to rejoin room on reconnection
      const storedRoomId = localStorage.getItem('currentRoomId');
      const storedPlayerNumber = localStorage.getItem('playerNumber');
      if (storedRoomId && storedPlayerNumber && !hasJoinedRoom) {
        joinRoom(storedRoomId, storedPlayerNumber);
      }
    };
  
    const onDisconnect = (reason) => {
      console.log('Disconnected from server. Reason:', reason);
      setConnectionStatus('disconnected');
      setIsConnectedToRoom(false);
      setHasJoinedRoom(false);
      setError("Disconnected from server. Attempting to reconnect...");
    };
  
    const onReconnectAttempt = (attemptNumber) => {
      console.log(`Reconnection attempt ${attemptNumber}`);
      setReconnectAttempts(attemptNumber);
    };
  
    const onRoomCreated = (roomData) => {
      console.log('Room created:', roomData);
      setRoom(roomData);
      setIsConnectedToRoom(true);
      setCurrentPlayerId(socket.id);
      setHasJoinedRoom(true);
      setPlayerNumber(1); // Creator is always Player 1
      setPlayers(roomData.players);
      setCurrentPlayerTurn(roomData.currentPlayerTurn);
      setGameStarted(roomData.gameStarted);
      localStorage.setItem('currentRoomId', roomData.id);
      localStorage.setItem('playerNumber', '1');
      navigate(`/room/${roomData.id}`);
    };
  
    const onJoinedRoom = (gameState) => {
      console.log('Joined room:', gameState);
      setRoom(gameState);
      setPlayers(gameState.players);
      setScores(gameState.scores);
      setArtist(gameState.currentArtist);
      setTrack(gameState.currentTrack);
      setCurrentPlayerTurn(gameState.currentPlayerTurn);
      setGameStarted(gameState.gameStarted);
      setIsConnectedToRoom(true);
      setCurrentPlayerId(socket.id);
      setHasJoinedRoom(true);
      
      // Set player number
      const playerIndex = gameState.players.findIndex(p => p.id === socket.id);
      setPlayerNumber(playerIndex + 1);
      localStorage.setItem('currentRoomId', gameState.id);
      localStorage.setItem('playerNumber', (playerIndex + 1).toString());
      resetGuessState();
    };
  
    const onUpdateGameState = (gameState) => {
      console.log('Game state updated:', gameState);
      setRoom(gameState);
      setPlayers(gameState.players);
      setScores(gameState.scores);
      setArtist(gameState.currentArtist);
      setTrack(gameState.currentTrack);
      setCurrentPlayerTurn(gameState.currentPlayerTurn);
      setGameStarted(gameState.gameStarted);
      setArtistTracks(gameState.artistTracks || []); 
      resetGuessState();
    };
  
    const onSocketError = ({ message }) => {
      console.error('Socket error:', message);
      setError(message);
    };
  
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('reconnect_attempt', onReconnectAttempt);
    socket.on('roomCreated', onRoomCreated);
    socket.on('joinedRoom', onJoinedRoom);
    socket.on('updateGameState', onUpdateGameState);
    socket.on('error', onSocketError);
  
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('reconnect_attempt', onReconnectAttempt);
      socket.off('roomCreated', onRoomCreated);
      socket.off('joinedRoom', onJoinedRoom);
      socket.off('updateGameState', onUpdateGameState);
      socket.off('error', onSocketError);
    };
  }, [socket, joinRoom, navigate, resetGuessState, hasJoinedRoom]);

  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  useEffect(() => {
    setSongSuggestions([]);
  }, [artist, track]);

  const startSinglePlayerGame = () => {
    setGameMode('single');
    setScores({ player: 0 });
  };

  const createMultiplayerRoom = useCallback(() => {
    setGameMode('multi');
    if (socket) {
      console.log('Emitting createRoom event');
      socket.emit('createRoom');
    }
    localStorage.removeItem('currentRoomId');
    localStorage.removeItem('playerNumber');
  }, [socket]);
  

  const fetchSuggestions = useCallback(async (query) => {
    if (!query.trim() || (gameMode === 'multi' && currentPlayerId !== currentPlayerTurn)) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await fetch(`${AUTH_SERVER_BASE}/search-artist?q=${encodeURIComponent(query)}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      setError("Unable to fetch suggestions. Please check your internet connection and try again.");
    }
  }, [gameMode, currentPlayerId, currentPlayerTurn]);

  const filterSongSuggestions = useCallback((input) => {
    if (!input.trim() || !artistTracks.length || (gameMode === 'multi' && currentPlayerId !== currentPlayerTurn)) {
      setSongSuggestions([]);
      return;
    }
    const filteredSongs = artistTracks.filter(track => 
      track.name.toLowerCase().includes(input.toLowerCase())
    );
    setSongSuggestions(filteredSongs.slice(0, 5)); 
  }, [artistTracks, gameMode, currentPlayerId, currentPlayerTurn]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (artist) {
        filterSongSuggestions(guess);
      } else {
        fetchSuggestions(searchTerm);
      }
    }, 300);
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, guess, artist, fetchSuggestions, filterSongSuggestions]);

  const fetchArtistTracks = useCallback(async (artistId) => {
    try {
      const response = await fetch(`${AUTH_SERVER_BASE}/artist-tracks/${artistId}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const tracks = await response.json();
      setArtistTracks(tracks);
      if (tracks.length > 0) {
        setTrack(tracks[Math.floor(Math.random() * tracks.length)]);
        resetGuessState();
      } else {
        setError("No tracks found for this artist.");
      }
    } catch (error) {
      setError(`An error occurred while fetching tracks: ${error.message}`);
    }
  }, [resetGuessState]);

  const searchArtist = useCallback(async (artistId, artistName) => {
    if ((!searchTerm.trim() && !artistId) || (gameMode === 'multi' && currentPlayerId !== currentPlayerTurn)) return;
  
    setLoading(true);
    setError(null);
  
    try {
      let data;
      if (!artistId) {
        const response = await fetch(`${AUTH_SERVER_BASE}/search-artist?q=${encodeURIComponent(searchTerm)}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        data = await response.json();
        if (data.length === 0) throw new Error("No artists found. Please try a different search term.");
        artistId = data[0].id;
        artistName = data[0].name;
      }
  
      if (gameMode === 'multi') {
        socket.emit('searchArtist', { 
          roomId: room.id, 
          artistId: artistId, 
          artistName: artistName 
        });
      } else {
        setArtist({ id: artistId, name: artistName });
        await fetchArtistTracks(artistId);
      }
    } catch (error) {
      setError(`An error occurred while searching for the artist: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [searchTerm, gameMode, currentPlayerId, currentPlayerTurn, socket, room, fetchArtistTracks]);

  

  const skipTrack = useCallback(() => {
    if (gameMode === 'multi' && socket && room) {
      socket.emit('skipTrack', { roomId: room.id });
    } else if (gameMode === 'single' && artistTracks.length > 0) {
      const newTrack = artistTracks[Math.floor(Math.random() * artistTracks.length)];
      setTrack(newTrack);
      resetGuessState();
    }
  }, [gameMode, socket, room, artistTracks, resetGuessState]);

  const handleGuess = useCallback((guessedTrack = null) => {
    if (track && (gameMode === 'single' || (gameMode === 'multi' && currentPlayerId === currentPlayerTurn))) {
      const isCorrect = (guessedTrack || guess).toLowerCase() === track.name.toLowerCase();
      setGuessResult(isCorrect ? "Correct!" : "Incorrect. Try again!");
      setIsCorrectGuess(isCorrect);
      if (isCorrect) {
        if (gameMode === 'single') {
          setScores(prevScores => ({ ...prevScores, player: (prevScores.player || 0) + 1 }));
          skipTrack();
        } else if (gameMode === 'multi' && socket && room) {
          socket.emit('correctGuess', { roomId: room.id, playerId: currentPlayerId });
        }
      } else {
        setGuess("");
        if (gameMode === 'multi') {
          socket.emit('incorrectGuess', { roomId: room.id, playerId: currentPlayerId });
        }
      }
      setSongSuggestions([]); 
    }
  }, [track, gameMode, currentPlayerId, currentPlayerTurn, guess, socket, room, skipTrack]);

  const playPreview = useCallback(() => {
    if (track && track.preview_url) {
      audio.src = track.preview_url;
      audio.play().catch((error) => {
        setError("Unable to play the audio preview. Skipping to next track.");
        skipTrack();
      });
      setIsPlaying(true);
      setTimeout(() => {
        audio.pause();
        setIsPlaying(false);
      }, 4000);
    } else {
      setError("No preview available for this track. Skipping to next track.");
      skipTrack();
    }
  }, [track, audio, skipTrack, setError, setIsPlaying]);

  const formatDate = (dateString) => {
    if (!dateString) return "Unknown Date";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };

  const revealAnswer = () => {
    setIsAnswerRevealed(true);
    setShowHint(true);
  };

  const copyRoomLink = useCallback(() => {
    const roomLink = `${window.location.origin}/room/${room.id}`;
    navigator.clipboard.writeText(roomLink).then(
      () => {
        setCopyFeedback('Link copied!');
        setTimeout(() => setCopyFeedback(''), 2000); 
      },
      (err) => {
        setCopyFeedback('Failed to copy');
        setTimeout(() => setCopyFeedback(''), 2000);
      }
    );
  }, [room]);

  const renderGameUI = () => (
    <div className="game-ui">
    
      
      <div className="players-list">
        {players.map((player, index) => (
          <div key={player.id} className="player">
            {player.name}: {scores[player.id] || 0}
            {currentPlayerTurn === player.id && " (Current Turn)"}
            {player.id === currentPlayerId && " (You)"}
          </div>
        ))}
      </div>

      {(gameMode === 'single' || (gameMode === 'multi' && gameStarted)) && (
      <form onSubmit={(e) => { e.preventDefault(); searchArtist(); }} className="search-container">
        <div ref={artistInputRef}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (gameMode === 'single' || currentPlayerId === currentPlayerTurn) {
                fetchSuggestions(e.target.value);
              }
            }}
            placeholder="Search for an artist"
            className="search-input"
            disabled={loading || (gameMode === 'multi' && currentPlayerId !== currentPlayerTurn)}
          />
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((artist) => (
                <li
                  key={artist.id}
                  onClick={() => {
                    setSearchTerm(artist.name);
                    setSuggestions([]);
                    searchArtist(artist.id, artist.name);
                  }}
                >
                  {artist.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button 
          type="submit" 
          className={`search-button ${loading || (gameMode === 'multi' && currentPlayerId !== currentPlayerTurn) ? "disabled" : ""}`} 
          disabled={loading || (gameMode === 'multi' && currentPlayerId !== currentPlayerTurn)}
        >
          {loading ? "Searching..." : "Search"}
        </button>
      </form>
    )}

      {artist && (
        <div className="artist-container">
          <h2 className="artist-name">{artist.name}</h2>
          {track && (
            <div className="track-controls">
              <button
                onClick={playPreview}
                disabled={isPlaying || loading}
                className={`play-button ${isPlaying || loading ? "disabled" : ""}`}
              >
                {isPlaying ? "Playing..." : "Play"}
              </button>
              {(gameMode === 'single' || (gameMode === 'multi' && currentPlayerId === currentPlayerTurn)) && (
                <>
                  <button
                    onClick={() => setShowHint(!showHint)}
                    className="hint-button"
                    disabled={loading}
                  >
                    {showHint ? "Hide Hint" : "Show Hint"}
                  </button>
                  <button
                    onClick={skipTrack}
                    className="skip-button"
                    disabled={loading}
                  >
                    Skip
                  </button>
                  <button
                    onClick={revealAnswer}
                    className="answer-button"
                    disabled={loading || isAnswerRevealed}
                  >
                    Answer
                    </button>
                </>
              )}
            </div>
          )}
        </div>
      )}

      

{track && isMyTurn && !isCorrectGuess && !isAnswerRevealed && (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      handleGuess();
    }}
    className="guess-container"
  >
    <div>
      <input
        type="text"
        value={guess}
        onChange={(e) => {
          setGuess(e.target.value);
          filterSongSuggestions(e.target.value);
        }}
        placeholder={`Player ${playerNumber}, guess the song`}
        className="guess-input"
      />
          {songSuggestions.length > 0 && (
            <ul className="song-suggestions-list">
              {songSuggestions.map((song) => (
                <li
                  key={song.id}
                  onClick={() => {
                    setGuess(song.name);
                    setSongSuggestions([]);
                    handleGuess(song.name);
                  }}
                >
                  {song.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button type="submit" className="guess-button">
          Guess
        </button>
      </form>
    )}

      {guessResult && (
        <div className={`guess-result ${isCorrectGuess ? "correct" : "incorrect"}`}>
          {guessResult}
        </div>
      )}

      {showHint && track && !isCorrectGuess && !isAnswerRevealed && (
        <div className="hint-container">
          <p>Hint: This song is from the album "{track.album.name}"</p>
          <img
            src={track.album.images[0].url}
            alt="Album cover"
            className="album-cover"
          />
        </div>
      )}

      {isAnswerRevealed && track && (
        <div className="song-details">
          <h3>The correct answer is: {track.name}</h3>
          <p><strong>Album:</strong> {track.album.name}</p>
          <p><strong>Release Date:</strong> {formatDate(track.album.release_date)}</p>
          <img
            src={track.album.images[0].url}
            alt="Album cover"
            className="album-cover"
          />
        </div>
      )}

      {error && (
      
          <p></p>
      
      )}

      {loading && <p className="loading">Loading...</p>}
    </div>
  );

  const getPageTitle = () => {
    if (!gameMode) return 'Music Guesser - Choose Game Mode';
    return `Music Guesser - ${gameMode === 'single' ? 'Single Player' : 'Multiplayer'}`;
  };

  const getPageDescription = () => {
    if (!gameMode) return 'Choose between Single Player and Multiplayer modes in Music Guesser, the ultimate music quiz game!';
    return 'Challenge yourself with Music Guesser! Search for artists, listen to previews, and guess the songs in this interactive music quiz game.';
  };

  const renderConnectionStatus = () => {
    if (connectionStatus === 'disconnected' && gameMode === 'multi'){
      return (
        <div className="alert warning">
          Disconnected from server. {reconnectAttempts > 0 ? `Reconnection attempt ${reconnectAttempts}...` : 'Attempting to reconnect...'}
        </div>
      );
    }
    return null;
  };

  if (!gameMode && !roomId) {
    return (
      <div className="container">
        <SEO
          title={getPageTitle()}
          description={getPageDescription()}
          keywords="music quiz, song guessing game, artist trivia, spotify game, single player, multiplayer"
        />
        <h1 className="title">Music Guesser</h1>
        <div className="mode-selection">
          <button onClick={startSinglePlayerGame} className="mode-button">Single Player</button>
        </div>
      </div>
    );
  }

  const isMyTurn = gameMode === 'single' || (gameMode === 'multi' && currentPlayerTurn === currentPlayerId);


  return (
    <div className="container">
      <SEO
        title={getPageTitle()}
        description={getPageDescription()}
        keywords="music quiz, song guessing game, artist trivia, spotify game"
      />

      <h1 className="title">Music Guesser - {gameMode === 'single' ? 'Single Player' : 'Multiplayer'}</h1>

      {renderConnectionStatus()}

      {gameMode === 'multi' && (
        <>
          {connectionStatus !== 'connected' && (
            <div className="alert warning">
              {connectionStatus === 'disconnected' ? 'Disconnected from server. Trying to reconnect...' : 'Error connecting to server. Please try again later.'}
            </div>
          )}

          {connectionStatus === 'connected' && !isConnectedToRoom && (
            <div>
              <p>Connected to server, waiting to join or create a room...</p>
              {!roomId && <button onClick={createMultiplayerRoom} className="create-room-button">Create New Room</button>}
            </div>
          )}

          {isConnectedToRoom && room && (!gameStarted || players.length < 2) && (
            <div className="room-info">
              <h2>Room: {room.id}</h2>
              <p>Players: {players.length}/2</p>
              <div className="room-link-container">
                <p>Share this link to invite players: </p>
                <input 
                  type="text" 
                  value={`${window.location.origin}/room/${room.id}`}
                  readOnly 
                  className="room-link-input"
                />
                <button onClick={copyRoomLink} className="copy-link-button">
                  Copy
                </button>
                {copyFeedback && <span className="copy-feedback">{copyFeedback}</span>}
              </div>
              
              {!gameStarted && <p>Waiting for more players to join...</p>}
            </div>
          )}
        </>
      )}

      {((gameMode === 'single') || (gameMode === 'multi' && gameStarted)) && renderGameUI()}
    </div>
  );
};

export default MusicGuesser;